.my-masonry-grid {
    display: -webkit-box !important; /* Not needed if autoprefixing */
    display: -ms-flexbox !important; /* Not needed if autoprefixing */
    display: flex !important;
    width: auto !important;
    background:
    linear-gradient(#E5E5E5,#E5E5E5) center/1px 100% no-repeat,
    linear-gradient(#E5E5E5,#E5E5E5) center/1px 100% no-repeat !important;
    background-position: 33.33% 40px, 66.66% 40px !important;
}

@media (max-width: 1200px) {
    .my-masonry-grid {
        background: linear-gradient(#E5E5E5,#E5E5E5) center/1px 100% no-repeat !important;
        background-position: 50.5% 40px !important;
    }
}

@media (max-width: 800px) {
    .my-masonry-grid {
        background: none !important;
    }
}

.my-masonry-grid_column {
    background-clip: padding-box !important;
}

body {
    margin: unset !important;
    background: #fff !important;
    font-family: 'Crimson Pro', serif !important;
}

svg {
    vertical-align: unset !important;
}
/* 
.bm-burger-button {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 26px;
    top: 20px;
  }
  
  .bm-burger-bars {
    background: #373a47;
  }
  
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  .bm-cross {
    background: #bdc3c7;
  }
  
 
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  .bm-item {
    display: inline-block;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  } */